import React from 'react'
import styled from 'styled-components'
import { Section } from '../components/shared'
import { PRIMARY } from '../constants/colors'
import { maxWidth, PHONE } from '../constants/measurements'
import { SUPPORT_EMAIL } from '../constants/routes'
import { withErrorHandler } from '../components/errorHandler'
import Layout from '../templates/Layout'

const Wrap = styled.div`
  display: flex;
  justify-content: flex;
  align-items: center;
`

const Container = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  max-width: 500px;
`

const Title = styled.div`
  color: ${PRIMARY};
  font-size: 48px;
  letter-spacing: 1px;
  margin-bottom: 24px;

  ${maxWidth(PHONE)} {
    font-size: 32px;
  }
`

const Body = styled.div`
  color: ${PRIMARY};
  font-size: 18px;
`

const Support = () => (
  <Layout>
    <Section>
      <Wrap>
        <Container>
          <Title>Contact Web Support</Title>
          <Body>
            If you have any questions or concerns, contact us at
            <a href={`mailto:${SUPPORT_EMAIL}`}> {SUPPORT_EMAIL}</a>.
          </Body>
        </Container>
      </Wrap>
    </Section>{' '}
  </Layout>
)

export default withErrorHandler(Support)
